<template>
	<v-footer padless class="footer">
		<div class="bg-img"></div>

		<div class="content pt-10 pb-16">
			<v-row no-gutters>
				<v-col cols="12" md="12" lg="10">
					<v-row>
						<v-col cols="12" md="12">
							<address>
								<strong class="text-primary">Dirección:</strong>
								Av. Santa Rosa 7314 int D-3 Jacarandas,
								Mazatlán, Sinaloa
								<p class="d-block d-lg-inline mt-4 mt-lg-0">
									<strong>Tel.</strong>
									<a class="" href="tel:6691194003"
										>(669) 119 4003 /119 6510</a
									>
								</p>
							</address>
						</v-col>
						<v-col cols="12" md="12">
							<v-divider
								style="background: #3d1152; border-width: 2px"
							></v-divider>
						</v-col>
					</v-row>
					<v-row class="mt-10 justify-space-between">
						<!-- logo -->
						<v-col cols="12" md="8" class="">
							<ContactForm />
						</v-col>

						<!-- REDES -->
						<v-col cols="12" md="auto" class="">
							<div class="mb-6">
								<h3 class="mb-2 text-center text-lg-left">
									Reservaciones:
								</h3>
								<ul class="">
									<li class="mb-1">
										<a
											class="d-block text-center text-md-left"
											href="mailto:gtereservas@checkh.mx"
											>gtereservas@checkh.mx</a
										>
									</li>
									<li class="mb-1">
										<a
											class="d-block text-center text-md-left"
											href="mailto:reservas1@checkh.mx"
											>reservas1@checkh.mx</a
										>
									</li>
									<li class="mb-1">
										<a
											class="d-block text-center text-md-left"
											href="mailto:reservas2@checkh.mx"
											>reservas2@checkh.mx</a
										>
									</li>
								</ul>
							</div>
							<div class="mb-6">
								<h3 class="mb-2 text-center text-lg-left">
									Grupos:
								</h3>
								<ul class="">
									<li class="mb-1">
										<a
											class="d-block text-center text-md-left"
											href="mailto:gtegrupos@checkh.mx"
											>gtegrupos@checkh.mx</a
										>
									</li>
									<li class="mb-1">
										<a
											class="d-block text-center text-md-left"
											href="mailto:grupos1@checkh.mx"
											>grupos1@checkh.mx</a
										>
									</li>
									<li class="mb-1">
										<a
											class="d-block text-center text-md-left"
											href="mailto:grupos2@checkh.mx"
											>grupos2@checkh.mx</a
										>
									</li>
								</ul>
							</div>
							<div class="mb-6">
								<h3 class="mb-2 text-center text-lg-left">
									Operaciones:
								</h3>
								<ul class="">
									<li class="mb-1">
										<a
											class="d-block text-center text-md-left"
											href="mailto:ecommerce@checkh.mx"
											>ecommerce@checkh.mx</a
										>
									</li>
								</ul>
							</div>
							<div class="mb-6">
								<h3 class="mb-2 text-center text-lg-left">
									Administración:
								</h3>
								<ul class="">
									<li class="mb-1">
										<a
											class="d-block text-center text-md-left"
											href="mailto:admon@checkh.mx"
											>admon@checkh.mx</a
										>
									</li>
								</ul>
							</div>
							<div class="">
								<h3 class="mb-2 text-center text-lg-left">
									Gerencia general:
								</h3>
								<ul class="">
									<li class="mb-1">
										<a
											class="d-block text-center text-md-left"
											href="mailto:direcion@checkh.mx"
											>direcion@checkh.mx</a
										>
									</li>
								</ul>
							</div>
							<v-row
								class="mt-8 justify-space-between align-center"
							>
								<v-col cols="auto" class="">
									<div class="logo">
										<router-link to="/">
											<img
												src="../../public/imgs/logo-checkhotel.svg"
												alt="logo-checkhotel"
											/>
										</router-link>
									</div>
								</v-col>
								<v-col cols="auto" class="d-md-none">
									<NavSociales />
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</div>
	</v-footer>
</template>

<script>
import ContactForm from './ContactForm.vue';
import NavSociales from './NavSociales.vue';
export default {
	components: { ContactForm, NavSociales },
};
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
@import '../assets/scss/partials/variables';

footer {
	background: #fff !important;
	position: relative !important;
	overflow: hidden !important;
	color: #ffffff;
	flex-direction: column;
}

address {
	// font-family: Roboto Condensed;
	font-size: $fs-xl;
	font-weight: 400;
	line-height: 29px;
	letter-spacing: 0em;
	text-align: justify;
	font-style: normal;
	text-align: center;
	color: #757575;
	strong,
	a {
		font-weight: 500 !important;
		color: #000000;
		text-transform: uppercase;
	}
	.text-primary {
		color: #490e67;
	}
}

h3 {
	//styleName: SubtituloContacto;
	font-family: 'Roboto Condensed';
	font-size: $fs-2xl;
	font-weight: 500;
	line-height: 29px;
	letter-spacing: 0em;
	color: #490e67;
	text-transform: uppercase;
}
a {
	//styleName: Parrafo;
	font-family: 'Roboto Condensed';
	font-size: $fs-lg;
	font-weight: 400;
	line-height: 23px;
	letter-spacing: 0em;
	color: #757575 !important;
}

.logo {
	width: 149px;
	height: 57.16px;
}

// a {
// 	&:hover {
// 		// color: $primary;
// 		// opacity: 0.6;
// 		text-decoration: underline;
// 	}
// }

@media #{map-get($display-breakpoints, 'sm-and-up')} {
	.logo {
		width: 209px;
		height: 57.16px;
	}
}
@media #{map-get($display-breakpoints, 'md-and-up')} {
	.bg-img {
		height: 100%;
		width: 100%;
		background-size: cover;
		position: absolute;
		background-position: center;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-image: url('../../public/imgs/footer.jpg');
		z-index: 0;
	}
	.content {
		z-index: 1;
	}
}
@media #{map-get($display-breakpoints, 'lg-and-up')} {
}
</style>
