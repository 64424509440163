<template>
	<section class="contact">
		<v-row class="mb-10">
			<v-col cols="12" md="12">
				<h2><span>Contacto</span> escríbenos</h2>
			</v-col>
		</v-row>
		<validation-observer ref="observer" v-slot="{ invalid }">
			<v-form @submit.prevent="sendForm">
				<v-row class="">
					<v-col class="pt-0" cols="12" sm="12">
						<validation-provider
							v-slot="{ errors }"
							name="El nombre"
							rules="required"
						>
							<v-text-field
								background-color="#F7F8F8"
								required
								id="name"
								ref="name"
								type="text"
								v-model.trim="form.name"
								height="48px"
								:error-messages="errors"
								placeholder="Nombre"
								outlined
								class="field"
							>
								<template v-slot:prepend-inner>
									<img
										class="pr-6"
										src="../../public/iconos/profile.svg"
										alt="profile"
									/>
								</template>
							</v-text-field>
						</validation-provider>
					</v-col>
					<v-col class="pt-0" cols="12" sm="12">
						<validation-provider
							v-slot="{ errors }"
							name="El correo"
							rules="required|email"
						>
							<v-text-field
								background-color="#F7F8F8"
								required
								id="email"
								ref="email"
								type="email"
								v-model.trim="form.email"
								height="48px"
								:error-messages="errors"
								placeholder="Correo elecrónico"
								outlined
							>
								<template v-slot:prepend-inner>
									<img
										class="pr-6"
										src="../../public/iconos/email.svg"
										alt="email"
									/>
								</template>
							</v-text-field>
						</validation-provider>
					</v-col>

					<v-col cols="12" class="pt-0">
						<validation-provider
							v-slot="{ errors }"
							name="El mensaje"
							rules="required"
						>
							<v-textarea
								background-color="#F7F8F8"
								requierd
								ref="message"
								v-model.trim="form.message"
								id="message"
								auto-grow
								placeholder="Mensaje"
								:row-height="
									$vuetify.breakpoint.xsOnly ? 30 : 40
								"
								:error-messages="errors"
								outlined
								><template v-slot:prepend-inner>
									<img
										class="pr-6"
										src="../../public/iconos/message.svg"
										alt="message"
									/> </template
							></v-textarea>
						</validation-provider>
					</v-col>

					<v-col class="" cols="12">
						<v-row
							class="justify-space-between align-center"
							no-gutters
						>
							<v-col cols="12" md="8" class="">
								<v-btn
									depressed
									tile
									large
									class="send"
									type="submit"
									:disabled="invalid"
									:loading="isLoading"
									>Enviar</v-btn
								>
							</v-col>
							<v-col
								cols="12"
								md="auto"
								class="d-none d-md-block"
							>
								<!-- REDES -->
								<NavSociales />
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-form>
		</validation-observer>
	</section>
</template>

<script>
import NavSociales from './NavSociales.vue';
export default {
	components: { NavSociales },
	data() {
		return {
			isDisabled: false,
			isLoading: false,
			form: {},
		};
	},
	methods: {
		async sendForm() {
			this.isDisabled = true;
			if (await this.$refs.observer.validate()) {
				this.isLoading = true;

				this.$store
					.dispatch('contact/simple', this.form)
					.then(() => {
						this.$swal(
							'Listo',
							'Hemos recibido su mensaje, pronto estaremos en contacto con usted',
							'success'
						);
						this.clear();
					})
					.catch((error) => this.$swal('Error', error, 'error'))
					.finally(() => {
						this.isLoading = false;
					});
			} else {
				console.log('error de validacion');
				// let form = {
				// 	name: null,
				// 	phone: null,
				// 	email: null,
				// 	message: null,
				// };
				// const inputForm = Object.keys(form);
				// for (let i = 0; i < inputForm.length; i++) {
				// 	const element = inputForm[i];
				// 	if (this.$refs[element].hasError) {
				// 		this.$refs[element].focus();
				// 		return;
				// 	}
				// }
			}
		},
		clear() {
			this.form = {};
			this.$refs.observer.reset();
		},
	},
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/partials/variables';

button.send {
	font-family: 'Roboto Condensed';
	width: 100% !important;
	height: 53px !important;
	max-width: 350px !important;
	height: 53px !important;
	border-radius: 7px !important;
	background-color: #490e67 !important;
	font-size: 20px !important;
	font-weight: 400 !important;
	line-height: 23px !important;
	letter-spacing: 0em !important;
	color: #fff !important;
	text-transform: initial !important;
}

h2 {
	font-family: 'Roboto Condensed';
	font-size: 30px;
	font-weight: 300;
	line-height: 35px;
	letter-spacing: 0em;
	text-transform: uppercase;
	span {
		font-weight: 500;
	}
}

// @media screen and (min-width: $sm) {
// 	p {
// 		font-size: 14px;
// 	}
// }
</style>
