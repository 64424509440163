<template>
	<div class="fill-height" style="">
		<div class="fill-height nav-siderbar">
			<ul class="mt-6 pt-16 pt-lg-0 mt-lg-0 fill-height">
				<li
					class="mb-10 px-lg-2 px-lg-4 mb-lg-0 d-block text-center d-lg-flex flex-column"
					v-for="(item, index) in items"
					:key="index"
				>
					<router-link
						class="text-center"
						:to="{ hash: `#${item.to}` }"
						@click.native="closeMenu"
					>
						<span>{{ item.title }}</span>
					</router-link>
					<!-- :class="{
							'active-link': $route.hash === '#nosotros',
						}" -->
				</li>
			</ul>
		</div>
		<!-- <div class="productos"></div>
		<div class="servicios"></div>
		<div class="categorias"></div> -->
	</div>
</template>

<script>
export default {
	data() {
		return {
			shoMenu: false,
			items: [
				{ title: 'Inicio', to: 'inicio' },
				{ title: 'Servicios', to: 'servicios' },
				{ title: 'Promociones', to: 'promociones' },
				{ title: 'Proveedores', to: 'proveedores' },
				{ title: 'Nosotros', to: 'nosotros' },
			],
		};
	},
	methods: {
		closeMenu() {
			this.$emit('closeMenu');
		},
		showSubMenuMovil() {
			console.log('mostrar w');
			this.$emit('show');
		},
	},
};
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
@import '../assets/scss/partials/variables';
.nav-siderbar {
	ul {
		padding: 0 !important;
		li {
			display: flex;
			justify-content: center;
			a {
				//styleName: Menu;
				font-family: 'Roboto Condensed';
				font-size: $fs-base;
				font-weight: 500;
				line-height: 18px;
				letter-spacing: 0.15em;
				color: #ff5100;
				text-transform: uppercase;

				&:hover {
					// color: $primary;
				}
			}
		}
	}
}
.router-link-exact-active.router-link-active {
	color: #490e67 !important;
	// font-family: Roboto !important;
	font-size: $fs-base !important;
	font-weight: 600 !important;
}
.active-link {
	color: red;
	background-color: green !important;
}
// @media screen and (min-width: $md) {
//   .nav {
//     position: fixed;
//     width: 100%;
//     max-width: 425px;
//     height: 100%;
//     right: 0;
//     left: auto;
//     // top: 55px;
//   }
// }
@media #{map-get($display-breakpoints, 'lg-and-up')} {
	.nav-siderbar {
		ul {
			display: flex !important;
			align-items: center !important;
			height: 100%;
			li {
				display: flex;
				justify-content: space-between;
				height: 100%;
				a {
					// display: flex;
					// align-items: center;
					//   font-size: 13px;
					// height: 100%;
					// padding-bottom: 30px;
					// border-bottom: 2px solid red;
					&:hover {
						// color: $primary;
					}
				}
			}
		}
	}
}
</style>
