import Vue from 'vue'
import Vuex from 'vuex'
import bannerModule from './banner'
import promotionModule from './promotion'
import contactModule from './contact'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    banner: bannerModule,
    promotion: promotionModule,
    contact: contactModule
  }
})
