<template>
	<div>
		<TheHeader class="" />
		<div class="">
			<router-view></router-view>
		</div>
		<TheFooter />
	</div>
</template>

<script>
import TheFooter from '@/components/TheFooter.vue';
import TheHeader from '@/components/TheHeader.vue';

export default {
	name: 'DefaultLayout',
	components: { TheHeader, TheFooter },
};
</script>

<style lang="scss" scoped></style>
