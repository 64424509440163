import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
// import '@fortawesome/fontawesome-free/css/all.css'; // Ensure you are using css-loader

Vue.use(Vuetify);

export default new Vuetify({
	// vuetify: {
	// 	customVariables: ['~/main/variables.scss'],
	// 	treeShake: true,
	// },
	theme: {
		themes: {
			light: {
				primary: '#B79A4B',
				secondary: '#b0bec5',
				accent: '#8c9eff',
				error: '#b71c1c',
			},
		},
	},
	// icons: {
	// 	iconfont: 'fa', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
	// },
});
