<template>
	<ul class="d-flex">
		<li
			class="icon mr-3 d-flex justify-center align-center"
			v-for="(item, index) in sociales"
			:key="index"
		>
			<v-btn
				depressed
				fab
				text
				:href="item.to"
				:small="$vuetify.breakpoint.xsOnly"
			>
				<img
					:src="require(`../../public/iconos/${item.icon}.svg`)"
					:alt="`Logo de ${item.icon}`"
				/>
			</v-btn>
		</li>
	</ul>
</template>

<script>
export default {
	data() {
		return {
			sociales: [
				{
					id: 1,
					title: 'facebook-checkhotel',
					to: 'https://www.facebook.com/checkhotel/',
					icon: 'facebook',
				},
				{
					id: 2,
					title: 'instagram-checkhotel',
					to: 'https://www.instagram.com/_checkhotel/',
					icon: 'instagram',
				},
			],
		};
	},
};
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

.icon {
	height: 25px;
	width: 25px;
}
@media #{map-get($display-breakpoints, 'sm-and-up')} {
	.icon {
		height: 45px;
		width: 45px;
	}
}
</style>
