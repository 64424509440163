export default {
    namespaced: true,

    state: {
        all: []
    },

    mutations: {
        setAll(state, all) {
            state.all = all
        }
    },

    actions: {
        loadAll({ commit }) {
            return this._vm.$axios.get('models/banners/all')
                .then(response => {
                    commit(
                        'setAll',
                        response.data.data
                    )
                    
                    return response.data.data
                })
        }
    },
    
    // getters: {
    //     uppercasedMessage(state) {
    //         return state.message.toUpperCase()
    //     }
    // }
}