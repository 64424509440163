import Vue from 'vue';

import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

// import { extend } from 'vee-validate';
import {
	required,
	email,
	min,
	max,
	confirmed,
	digits,
	regex,
} from 'vee-validate/dist/rules';

extend('required', {
	...required,
	message: '{_field_} es requerido',
});
extend('requiredF', {
	...required,
	message: '{_field_} es requerida',
});

extend('email', {
	...email,
	message: 'El correo electrónico debe ser válido',
});

extend('min', {
	...min,
	message: '{_field_} debe tener {length} caracteres.',
	// message: '{_field_} needs to be {length} digits. ({_value_})',
});

extend('max', {
	...max,
	message: '{_field_} no puede tener más de {length} caracteres.',
});

extend('confirmed', {
	...confirmed,
	message: 'Las contraseñas no coinciden',
});

extend('digits', {
	...digits,
	message: '{_field_} debe tener {length} digitos y númerico',
});

extend('regex', {
	...regex,
	message: '{_field_} {_value_} no coincide {regex}',
});

extend('rfcValidate', (value) => {
	const regex =
		/[A-Z,,&]{3,4}[0-9]{2}[0-1][0-9][0-3][0-9][A-Z,0-9]?[A-Z,0-9]?[0-9,A-Z]?/;
	if (regex.test(value)) {
		return true;
	}

	return 'El RFC debe ser válido';
});
extend('cardNumberValidate', {
	validate: (value) => {
		return OpenPay.card.validateCardNumber(value);
	},
	message: '{_field_} no es válido',
});
extend('cvcValidate', {
	validate: (value) => {
		return OpenPay.card.validateCVC(value);
	},
	message: '{_field_} no es válido',
});

extend('dateExpiryValidate', {
	params: ['target'],
	validate: (value, { target }) => {
		console.log(value);
		console.log(target);
		return OpenPay.card.validateExpiry(target, value);
	},
	message: 'La fecha de exp no es válida',
});
