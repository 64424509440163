export default {
    namespaced: true,

    state: {
        all: []
    },

    mutations: {
        setAll(state, all) {
            state.all = all
        }
    },

    actions: {
        loadAll({ commit }, payload = {}) {
            let params = {}

            if(payload.scopes) {
                params.scopes = payload.scopes
            }

            if(payload.appends) {
                params.appends = payload.appends
            }

            return this._vm.$axios.get(
                'models/promotions/all', {
                    params: params
                })
                .then(response => {
                    commit(
                        'setAll',
                        response.data.data
                    )
                    
                    return response.data.data
                })
        }
    },
    
    getters: {
        orderAllByIdAsc(state) {
            return state.all.sort((a, b) => a.id - b.id)
        }
    }
}