import Vue from 'vue';
import VueRouter from 'vue-router';
import DefaultLayout from '../layouts/DefaultLayout.vue';
import goTo from 'vuetify/lib/services/goto';

Vue.use(VueRouter);
let options = {
	duration: 1000,
	offset: 0,
	easing: 'easeInOutCubic',
};

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior: (to, from, savedPosition) => {
		let scrollTo = 0;

		if (to.hash) {
			scrollTo = to.hash;
		} else if (savedPosition) {
			scrollTo = savedPosition.y;
		}

		return goTo(scrollTo, options);
	},
	routes: [
		{
			path: '/',
			component: DefaultLayout,
			children: [
				{
					path: '/',
					name: 'HomeView',
					component: () =>
						import(
							/* webpackChunkName: "HomeView" */ '../views/HomeView.vue'
						),
				},
			],
		},
	],
});

export default router;
