export default {
    namespaced: true,

    actions: {
        simple({ commit }, payload = {}) {
            return this._vm.$axios.post(
                    'contacts/simple', payload
                )
                .catch(
                    error => Promise.reject(Object.values(error.response.data.errors)[0][0])
                )
        },

        project({ commit }, payload = {}) {
            return this._vm.$axios.post(
                    'contacts/project', payload
                )
                .catch(
                    error => Promise.reject(Object.values(error.response.data.errors)[0][0])
                )
        }
    }
}